<script>
import ProjectsChangeModal from "@/components/modals/ProjectsChangeModal.vue";

export default {
    components: {ProjectsChangeModal},
    props: {
        params: Object
    },
    data() {
        return {
            selectedItem: {},
            showData: false,
        }
    },
    methods: {
        // show() {
        //     this.selectedItem = this.params.data;
        //     this.showData = true;
        // },
        close() {
            this.selectedItem = {};
            this.showData = false;
        }
    },
}
</script>

<template>
    <button type="button" class="cursor-pointer bg-mainColor text-white px-4" @click="show">
        Редактировать
    </button>
    <ProjectsChangeModal :show="showData" @close="close" :model="params.data" />
</template>