<script>
import {AgGridVue} from "ag-grid-vue3";
import "ag-grid-community/styles//ag-grid.css";
import "ag-grid-community/styles//ag-theme-alpine.css";
import {ref} from 'vue';
import {notify} from "@kyvg/vue3-notification";
// import router from "@/router";
import api from '../../services/api';
import ProjectChangeBtn from "@/components/parts/tableButtons/ProjectChangeBtn.vue";

export default {
    components: {
        AgGridVue,
    },
    setup() {
        const rowData = ref(null);
        const openChangeModal = ref(false);

        return {
            openChangeModal,
            rowData,
            selectedRows: null,
            gridApi: null,
            rowSelection: null,
            defaultColDef: {
                sortable: true,
                flex: 1,
                minWidth: 100,
                filter: true,
                resizable: true,
            },
            columnDefs: [
                {headerName: "Имя", field: "name", filter: 'agTextColumnFilter', floatingFilter: true},
                {headerName: "Описание", field: "description", filter: 'agTextColumnFilter', floatingFilter: true},
                {headerName: "Владелец", field: "owner", filter: 'agTextColumnFilter', floatingFilter: true},
                {
                    headerName: "Номер контракта",
                    field: "contractNumber",
                    filter: 'agTextColumnFilter',
                    floatingFilter: true
                },
                {headerName: "Статус", field: "status", filter: 'agTextColumnFilter', floatingFilter: true},
                {
                    field: '',
                    cellRenderer: ProjectChangeBtn,
                },
                {
                    field: "",
                    cellRenderer: () => {
                        return '<button class="w-full bg-mainColor text-white max-w-[200px]">Выбрать</button>'
                    },
                    onCellClicked: (params) => {
                        notify({
                            title: 'Проект изменён на:',
                            text: params.data.name,
                        });
                        localStorage.setItem('projectId', params.data.id);
                        window.location.replace('/');
                    }
                },
            ],
            currentPage: 0,
            totalPages: 1,
            pageSize: 20,
            queryStr: '',
            filtersArr: [],
        }
    },
    methods: {
        onGridReady(params) {
            this.gridApi = params.api;

            this.getData();

            params.api.addGlobalListener((type, e) => {
                if (type === "rowClicked") {
                    this.$emit('changeModal', e);
                }
            })
        },
        getData() {
            this.queryStr = '';

            for (var key in this.filtersArr) {
                let queryPart = '&' + key + '=' + this.filtersArr[key].filter;
                this.queryStr = this.queryStr + queryPart;
            }

            api.get('/projects/all/with-page' +
                '?page='+ this.currentPage +
                '&size=' + this.pageSize +
                this.queryStr
            ).then((res) => {
                this.rowData = res.data.data.content;
                this.totalPages = res.data.data.totalPages;
            }).catch((res) => {
                console.log(res);
                notify({
                    title: 'Ошибка, повторите позднее',
                })
            })
        },
        onFilterChanged(params) {
            let filterModel = params.api.getFilterModel();
            this.filtersArr = filterModel;

            if (filterModel.createdAt) {    
                filterModel['startDateCreatedAt'] = {"filter": filterModel.createdAt.dateFrom};

                if (filterModel.createdAt.dateto == null) {
                    filterModel['endDateCreatedAt'] = {"filter": filterModel.createdAt.dateFrom};
                }else {
                    filterModel['endDateCreatedAt'] = {"filter": filterModel.createdAt.dateTo};
                }
                
                this.filtersArr = filterModel;

                delete this.filtersArr.createdAt;
            }

            this.currentPage = 0;

            this.getData();
        },
        add(item) {
            this.rowData.unshift(item);
        },
        change(item) {
            this.rowData = this.rowData.filter(obj => {
                return obj.id !== item.id;
            })
            this.rowData.unshift(item);
        },
        delete(item) {
            this.rowData = this.rowData.filter(obj => {
                return obj.id !== item.id;
            })
        },
        nextEl() {
            if (this.currentPage < this.totalPages - 1) {
                this.currentPage++;
                this.getData();
            }
        },
        prevEl() {
            if (!this.currentPage <= 0) {
                this.currentPage--;
                this.getData();
            }
        },
    },
}
</script>

<template>
    <ag-grid-vue
        @grid-ready="onGridReady"
        @selection-changed="onSelectionChanged"
        style="height: 650px;"
        :defaultColDef="defaultColDef"
        rowSelection="single"
        class="ag-theme-alpine px-2 py-2"
        :columnDefs="columnDefs"
        :onFilterChanged="onFilterChanged"
        :rowData="rowData">
    </ag-grid-vue>

    <div class="flex w-full px-2 py-2 items-center justify-between gap-8">
        <select @change="getData()" v-model="pageSize" name="pageSize" class="rounded-xl h-[30px] border border-gray-700 px-4 font-semibold">
            <option value="" disabled selected="selected">Размер страницы</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="1000">1000</option>
        </select>

        <div class="flex h-[30px] rounded-xl overflow-hidden justify-between bg-gray-900 text-white w-[200px]">
            <div @click="prevEl()" class="flex h-full w-[50px] justify-center items-center hover:shadow-blue-500/40 hover:bg-blue-500 cursor-pointer transition-all">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                </svg>
            </div>
            <div class="flex h-full justify-center items-center">
                {{ currentPage+1 }} из {{ totalPages }}
            </div>
            <div @click="nextEl()" class="flex h-full w-[50px] justify-center items-center hover:shadow-blue-500/40 hover:bg-blue-500 cursor-pointer transition-all">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                </svg>
            </div>
        </div>
    </div>
</template>

<style>
.ag-header-container {
    background: #fff;
}

.ag-header-cell-text {
    color: #000;
}

.ag-header-container > .ag-header-row-column {
    background: #f3f4f6;
    color: #000;
}

.vue-notification-template {
    background: #4f46e5;
}
</style>