<script>
import { defineAsyncComponent } from 'vue';
import ProjectsTable from '../components/tables/ProjectsTable.vue';

const ProjectChangeModal = defineAsyncComponent(() => 
    import('@/components/modals/ProjectsChangeModal.vue')
);

const ProjectCreateModal = defineAsyncComponent(() => 
    import('@/components/modals/ProjectCreateModal.vue')
);

export default {
    components: {
        ProjectCreateModal,
        ProjectChangeModal,
        ProjectsTable,
    },
    data() {
        return {
            createModal: false,
            changeModal: false,
            changeModel: {},
        }
    },
    methods: {
        addData(item) {
            this.$refs.table.add(item);
        },
        showChangeModal(e) {
            this.changeModal = true;
            this.changeModel = e.data;
        },
        changeTableData(item) {
            this.$refs.table.change(item);
        },
        deleteTableData(item) {
            this.$refs.table.delete(item);
        },
        checkProject() {
            if (localStorage.getItem('projectId')) {
                return true;
            } else {
                return false;
            }
        }
    }
}

</script>

<template>
    <h3 class="text-3xl font-medium text-gray-700 text-start">Проекты</h3>
    <div class="mt-4 flex flex-col">
        <button @click="createModal = true" class="w-full max-w-[200px] px-4 py-2 text-sm text-center text-white bg-indigo-600 rounded-md focus:outline-none hover:bg-indigo-500 self-start transition-all">Создать новый проект</button>
    </div>
    <div class="flex w-full h-[100px] bg-green-300 mt-4 rounded-md justify-center gap-10 items-center p-4" v-if="!checkProject()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
        </svg>
        <p class="font-medium text-[20px]">Для продолжения необходимо выбрать существующий, или создать новый проект</p>
    </div>
    <div class="mt-6">
        <h4 class="text-gray-600 text-left">Все проекты</h4>
        <div class="my-2 overflow-hidden bg-white rounded-[10px] shadow">
            <ProjectsTable ref="table" @changeModal="showChangeModal"/>
        </div>
    </div>
    <ProjectCreateModal :show="createModal" @close="createModal=false" @addData="addData"/>
    <ProjectChangeModal v-if="changeModal" :show="changeModal" :model="changeModel" @close="changeModal=false" @changeData="changeTableData" @deleteData="deleteTableData"/>
</template>