<script>
    import api from '../../services/api';
    import ModalJetstream from './ModalJetstream.vue';
    import { notify } from "@kyvg/vue3-notification";

export default {
    props: [
        'showModal',
        'model',
    ],
    data() {
        return {
            show: this.showModal,
            form: {
                name: this.model.name,
                description: this.model.description,
                contractNumber: this.model.contractNumber,
                status: this.model.status,
            }
        }
    },
    components: {
        ModalJetstream,
    },
    methods: {
        sendForm() {
            api.put('/projects/'+this.model.id,
                this.form
            ).then((res) => {
                    notify({
                        title: 'Проект успешно изменён!',
                    });
                    this.$emit('changeData', res.data.data);
                    this.$emit('close');

                    this.addHistory('Изменение проекта', res.data.data.name);
                }).catch((res) => {
                    console.log(res);
                    notify({
                        title: 'Ошибка, повторите позднее',
                    })
            })
        },
        deleteProject() {
            api.delete('/projects/'+this.model.id)
                .then((res) => {
                    if (res.data.success == true) {
                        notify({
                            title: 'Проект успешно удалён!',
                        });
                        this.$emit('deleteData', this.model);
                        this.$emit('close');

                        this.addHistory('Удаление проекта', this.model.name);
                    }
                }).catch((res) => {
                    console.log(res);
                    notify({
                        title: 'Ошибка, повторите позднее',
                    })
            })
        },
        isActiveProject() {
            return this.model.id == localStorage.getItem('projectId');
        }
    },
}

</script>

<template>
    <ModalJetstream :show="show" @close="$emit('close')">
        <div class="px-6 py-4 text-left modal-content">
            <div class="flex items-center justify-between pb-3">
                <p class="text-2xl font-bold">Редактирование проекта</p>
                <div class="z-50 cursor-pointer modal-close" @click="$emit('close')">
                    <svg class="text-black fill-current" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path></svg>
                </div>
            </div>
            <form action="" class="mt-4" @submit.prevent="sendForm($event)">
                <label for="name" class="flex flex-col justify-start">
                    <span class="text-sm text-gray-700 text-left">Имя</span>
                    <input v-model="form.name" type="text" name="name" class="block h-[42px] border w-full mt-1 border-gray-200 rounded-md focus:border-[#4f46e5] p-3" required>
                </label>
                <label for="description" class="flex flex-col justify-start mt-4">
                    <span class="text-sm text-gray-700 text-left">Описание</span>
                    <input v-model="form.description" type="text" name="description" class="block h-[42px] border w-full mt-1 border-gray-200 rounded-md focus:border-[#4f46e5] p-3" required>
                </label>
                <label for="contractNumber" class="flex flex-col justify-start mt-4">
                    <span class="text-sm text-gray-700 text-left">Номер контракта</span>
                    <input v-model="form.contractNumber" type="text" name="contractNumber" class="block h-[42px] border w-full mt-1 border-gray-200 rounded-md focus:border-[#4f46e5] p-3" required>
                </label>

                <div class="flex justify-end pt-2">
                    <button type="button" @click="deleteProject()" class="p-3 px-6 py-3 mr-2 text-white bg-red-500 rounded-lg hover:bg-red-400 focus:outline-none hover:cursor-pointer transition-all" :disabled="isActiveProject()">Удалить проект</button>
                    <button type="submit" class="px-6 py-3 font-medium tracking-wide text-white bg-indigo-600 rounded-md hover:bg-indigo-500 focus:outline-none transition-all">Сохранить изменения</button>
                </div>
            </form>
        </div>
    </ModalJetstream>
</template>