<script>
import axios from 'axios';
import { notify } from "@kyvg/vue3-notification";

export default{
    data() {
        return {
            form: {
                email: null,
                password: null,
                firstname: null,
                lastname: null,
            },
            activeComponent: 'AuthLoginPart',
        }
    },
    methods: {
        sendAuth() {
            axios.post(process.env.VUE_APP_BASE_URL+ '/auth/signup', this.form)
                .then((res) => {
                    if (res.data.success) {
                        notify({
                            title: 'Пользователь успешно создан!',
                        });
                    }

                    this.sendLogin();
                    this.refreshForm();
                })
                .catch((res) => {
                    console.log(res);
                    notify({
                        title: 'Ошибка, повторите позднее',
                    })
                })
        },
        sendLogin() {
            axios.post(process.env.VUE_APP_BASE_URL + '/auth/login', {
                "username": this.form.email,
                "password": this.form.password,
            }).then((res) => {
                if (res.data.success) {
                    const unixtime = JSON.parse(atob(res.data.data.refresh_token.split('.')[1])).exp;

                    localStorage.setItem('token', res.data.data.access_token);
                    localStorage.setItem('refreshToken', res.data.data.refresh_token);
                    localStorage.setItem('refreshExp', unixtime*1000);
                    if (localStorage.getItem('projectId')) {
                        localStorage.removeItem('projectId');
                    }
                    localStorage.setItem('userHistory', '[]');
                    this.$router.go();
                }else{
                    notify({
                        title: 'Ошибка',
                        text: res.message,
                    });
                }
            }).catch((res) => {
                notify({
                    title: 'Ошибка',
                    text:res.message
                })
            })
        },
        refreshForm() {
            this.form.email = null;
            this.form.password = null;
            this.form.firstname = null;
            this.form.lastname = null;
        }
    }
}

</script>

<template>
    <form action="" class="mt-4" @submit.prevent="sendAuth($event)">
        <label for="email" class="flex flex-col justify-start">
            <span class="text-sm text-gray-700 text-left">E-mail</span>
            <input v-model="form.email" type="text" name="email" class="block h-[42px] border w-full mt-1 border-gray-200 rounded-md focus:border-[#4f46e5] p-3">
        </label>
        <label for="password" class="flex flex-col justify-start mt-4">
            <span class="text-sm text-gray-700 text-left">Password</span>
            <input v-model="form.password" type="password" name="password" class="block h-[42px] border w-full mt-1 border-gray-200 rounded-md focus:border-[#4f46e5] p-3">
        </label>
        <label for="firstname" class="flex flex-col justify-start mt-4">
            <span class="text-sm text-gray-700 text-left">Firstname</span>
            <input v-model="form.firstname" type="text" name="firstname" class="block h-[42px] border w-full mt-1 border-gray-200 rounded-md focus:border-[#4f46e5] p-3">
        </label>
        <label for="lastname" class="flex flex-col justify-start mt-4">
            <span class="text-sm text-gray-700 text-left">Lastname</span>
            <input v-model="form.lastname" type="text" name="lastname" class="block h-[42px] border w-full mt-1 border-gray-200 rounded-md focus:border-[#4f46e5] p-3">
        </label>
        <div class="mt-6">
            <button type="submit" class="w-full px-4 py-2 text-sm text-center text-white bg-indigo-600 rounded-md focus:outline-none hover:bg-indigo-500 transition-all">Зарегистрироваться</button>
        </div>
    </form>
</template>