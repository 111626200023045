import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/projects',
    name: 'projects',
    // route level code-splitting
    // this generates a separate chunk (projects.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "projects" */ '../views/ProjectsView.vue')
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import('../views/ContactsView.vue')
  },
  {
    path: '/signs',
    name: 'signs',
    component: () => import('../views/SignsView.vue')
  },
  {
    path: '/groups',
    name: 'groups',
    component: () => import( '../views/GroupsView.vue')
  },
  {
    path: '/group-info/:id',
    name: 'group-info',
    component: () => import( '../views/GroupsInfoView.vue')
  },{
    path: '/sms-report',
    name: 'sms-report',
    component: () => import( '../views/SmsReportView.vue')
  },

  {
    path: '/dispatches',
    name: 'dispatches',
    component: () => import('../views/DispatchesView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/AuthView.vue')
  },
  {
    path: '/sms-history',
    name: 'SmsHistory',
    component: () => import('../views/SmsHistory.vue')
  },
  // {
  //   path: '/sms-history/:dispatchId',
  //   name: 'SmsHistory',
  //   component: () => import('../views/SmsHistory.vue')
  // },
  {
    path: '/stop-list',
    name: 'StopList',
    component: () => import('../views/StopListView.vue')
  },
  {
    path: '/black-list',
    name: 'BlackList',
    component: () => import('../views/BlackListView.vue')
  },

  {
    path: '/admin/signs',
    name: 'adminSigns',
    component: () => import('../views/admin/SignsView.vue')
  },
  {
    path: '/admin/projects',
    name: 'adminProjects',
    component: () => import('../views/admin/ProjectsView.vue')
  },
  {
    path: '/admin/sms-history',
    name: 'adminSmsHistory',
    component: () => import('../views/admin/SmsHistory.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
