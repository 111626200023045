import axiosInstance from "./api";
import axios from "axios";
import router from "@/router";

const setup = () => {
    axiosInstance.interceptors.request.use((config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers["Authorization"] = 'Bearer ' + token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
    );

    axiosInstance.interceptors.response.use((res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;

        if (err.response.status === 401 || err.response.status === 302 && !originalConfig._retry) {
            
            const now = Date.now();

            const refreshToken = localStorage.getItem('refreshToken');

            if (refreshToken) {
                if (now <= localStorage.getItem('refreshExp')) {
                    
                    originalConfig._retry = true;
                    try {
                        const rs = await axios.post(process.env.VUE_APP_BASE_URL + "/auth/refresh-token", {
                            refreshToken: localStorage.getItem('refreshToken')
                        }).catch(() => {
                            localStorage.removeItem('token');
                            router.go();
                        });

                        const accessToken = rs.data.data.access_token;
                        localStorage.setItem('token', accessToken);
                        return axiosInstance(originalConfig);
                    } catch (_error) {
                        return Promise.reject(_error);
                    }
                } else {
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('projectId');
                    localStorage.removeItem('refreshExp');

                    router.go();
                }
            }else{
                localStorage.removeItem('token');
                router.go();
            }
        }

        return Promise.reject(err);
    });
}

export default setup;