<script>
import AuthLoginPart from './../components/parts/AuthLoginPart.vue';
import AuthSignupPart from './../components/parts/AuthSignupPart.vue';

export default{
    components: {
        AuthLoginPart,
        AuthSignupPart,
    },
    data() {
        return {
            activeComponent: 'AuthLoginPart',
            activeLink: null,
        }
    },
    methods: {
        toggleComponent(event, comp) {
            if (this.activeLink) {
                this.activeLink.classList.remove('active');
            }
            this.activeComponent = comp;
            this.activeLink = event.currentTarget;
            this.activeLink.classList.add('active');
        },
        setActiveLink() {
            this.activeLink = document.querySelector('#first');
            this.activeLink.classList.add('active');
        },
        getTitle() {
            return process.env.VUE_APP_TITLE;
        }
    },
    mounted() {
        this.setActiveLink();
    }
}

</script>

<template>
    <div class="flex w-full h-[100vh] justify-center items-center bg-[url(https://images.pexels.com/photos/1261728/pexels-photo-1261728.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1)] bg-no-repeat bg-cover">
        <div class="w-full max-w-sm p-6 bg-white rounded-md shadow-md">
            <div class="flex items-center justify-center">
                <svg class="w-10 h-10" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M364.61 390.213C304.625 450.196 207.37 450.196 147.386 390.213C117.394 360.22 102.398 320.911 102.398 281.6C102.398 242.291 117.394 202.981 147.386 172.989C147.386 230.4 153.6 281.6 230.4 307.2C230.4 256 256 102.4 294.4 76.7999C320 128 334.618 142.997 364.608 172.989C394.601 202.981 409.597 242.291 409.597 281.6C409.597 320.911 394.601 360.22 364.61 390.213Z" fill="#4C51BF" stroke="#4C51BF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M201.694 387.105C231.686 417.098 280.312 417.098 310.305 387.105C325.301 372.109 332.8 352.456 332.8 332.8C332.8 313.144 325.301 293.491 310.305 278.495C295.309 263.498 288 256 275.2 230.4C256 243.2 243.201 320 243.201 345.6C201.694 345.6 179.2 332.8 179.2 332.8C179.2 352.456 186.698 372.109 201.694 387.105Z" fill="white"></path></svg>
                <span class="text-2xl font-semibold text-gray-700">{{ getTitle() }}</span>
            </div>
            <div class="flex flex-col">
                <div class="flex rounded-md h-[36px] overflow-hidden mt-[20px] outline-1 outline outline-indigo-600">
                    <button id="first" @click="toggleComponent($event, 'AuthLoginPart')" class="w-1/2 bg-white text-black flex justify-center items-center hover:bg-indigo-600 hover:text-white transition-all">Вход</button>
                    <button @click="toggleComponent($event, 'AuthSignupPart')" class="w-1/2 bg-white text-black flex justify-center items-center hover:bg-indigo-600 hover:text-white transition-all">Регистрация</button>
                </div>
                <component :is="activeComponent"></component>
            </div>
        </div>
    </div>
</template>

<style scoped>
.active{
    background: #4f46e5;
    color: #fff;
}
</style>