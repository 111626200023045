<script>
import api from '../services/api';

  export default{
    data() {
      return {
        contactsCount: 0,
        dispatchesCount: 0,
        projectName: 'Не выбран',
        userHistory: [],
        dispatches: null,
        lastDispatch: null,
        rolesArr: {},
      }
    },
    methods: {
      getProjectName() {
        api.get('/projects/' + localStorage.getItem('projectId'))
          .then((res) => {
            this.projectName = res.data.data.name;
          }).catch(() => {
            return 'NULL';
          })
      },
    getProjectId() {
      if (localStorage.getItem('projectId')) {
          return localStorage.getItem('projectId');
      }
      return 'Проект не выбран';
    },
      getUserHistory() {
        let arr = localStorage.getItem('userHistory');
        this.userHistory = JSON.parse(arr).reverse();
      },
      getContactsCount() {
        // api.get('/projects/' + localStorage.getItem('projectId') + '/contacts/all?page=0&size=99999')
        //   .then((res) => {
        //     this.contactsCount = res.data.data.content.length;
        //   }).catch(() => {
        //     this.contactsCount = 0;
        //   })
      },
      getDispatchesCount() {
        api.get('/projects/' + localStorage.getItem('projectId') + '/dispatches/all')
          .then((res) => {
            this.dispatchesCount = res.data.data.length;
            this.dispatches = res.data.data;
            this.lastDispatch = this.dispatches.slice(-1)[0];
          }).catch(() => {
            this.dispatchesCount = 0;
          })
      },
      getRole() {
            api.get('/auth/' + JSON.parse(atob(localStorage.getItem('token').split('.')[1])).sub + '/get-roles')
                .then((res) => {
                    this.rolesArr = res.data.data;
                })
                .catch((res) => {
                    console.log(res);
                })

            return true;
        },
        checkRole(role) {
            return Object.values(this.rolesArr).includes(role);
        },
    },
    mounted() {
      this.getProjectName();
      this.getUserHistory();
      this.getContactsCount();
      this.getDispatchesCount();
      this.getRole();
    }
  }
</script>

<template>
  <h3 class="text-3xl font-medium text-gray-700 text-start">Главная</h3>
  <div class="mt-4">
    <div class="flex flex-wrap -mx-6 2xl:flex-row flex-col gap-[25px] 2xl:gap-0 items-center 2xl:items-start">
      <div class="flex flex-col w-[60%]">
        <div class="flex w-full justify-between px-6 gap-6 flex-col 2xl:flex-row">
          <div class="w-full">
            <div class="flex items-center px-5 py-6 bg-white rounded-md shadow-sm">
              <div class="p-3 bg-indigo-600 bg-opacity-75 rounded-full text-white">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
                </svg>
              </div>
              <div class="mx-5 text-left">
                <h4 class="text-2xl font-semibold text-gray-700">{{ contactsCount }}</h4>
                <div class="text-gray-500">Контактов</div>
              </div>
            </div>
          </div>
          <div class="w-full">
            <div class="flex items-center px-5 py-6 bg-white rounded-md shadow-sm">
              <div class="p-3 bg-indigo-600 bg-opacity-75 rounded-full text-white">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                </svg>
              </div>
              <div class="mx-5 text-left">
                <h4 class="text-2xl font-semibold text-gray-700">{{ dispatchesCount }}</h4>
                <div class="text-gray-500">Рассылок</div>
              </div>
            </div>
          </div>
          <div class="w-full">
            <div class="flex items-center px-5 py-6 bg-white rounded-md shadow-sm">
              <div class="p-3 bg-indigo-600 bg-opacity-75 rounded-full text-white">
                <svg class="w-5 h-5" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 3C6.44772 3 6 3.44772 6 4C6 4.55228 6.44772 5 7 5H13C13.5523 5 14 4.55228 14 4C14 3.44772 13.5523 3 13 3H7Z" fill="currentColor"></path><path d="M4 7C4 6.44772 4.44772 6 5 6H15C15.5523 6 16 6.44772 16 7C16 7.55228 15.5523 8 15 8H5C4.44772 8 4 7.55228 4 7Z" fill="currentColor"></path><path d="M2 11C2 9.89543 2.89543 9 4 9H16C17.1046 9 18 9.89543 18 11V15C18 16.1046 17.1046 17 16 17H4C2.89543 17 2 16.1046 2 15V11Z" fill="currentColor"></path>
                </svg>
              </div>
              <div class="mx-5 text-left">
                <h4 class="text-2xl font-semibold text-gray-700 max-w-[150px] overflow-hidden text-ellipsis">{{ projectName }}</h4>
                <div class="text-gray-500">Проект</div>
              </div>
            </div>
          </div>
        </div>
          <div v-if="checkRole('API_USER')" class="flex px-6 mt-6 h-full">
              <div class="flex w-full bg-white rounded-md shadow-sm px-5 py-2 h-full text-left gap-2">
                  <b>ID проекта:</b>
                  <p>{{getProjectId()}}</p>
              </div>
          </div>
        <div class="flex px-6 mt-6 h-full">
          <div class="flex flex-col w-full bg-white rounded-md shadow-sm px-5 py-6 h-full min-h-[492px]">
            <p class="text-2xl font-semibold text-gray-700 text-left border-b-[4px] border-[#4f46e5] pb-5">Последняя рассылка</p>
            <div class="flex w-full flex-col text-left mt-4 gap-4" v-if="lastDispatch">
              <div class="flex flex-col">
                <h4 class="text-2xl font-semibold text-gray-700">Имя</h4>
                <p class="text-gray-500">{{ lastDispatch.name }}</p>
              </div>
              <div class="flex flex-col">
                <h4 class="text-2xl font-semibold text-gray-700">Текст</h4>
                <p class="text-gray-500">{{ lastDispatch.text }}</p>
              </div>
              <div class="flex flex-col">
                <h4 class="text-2xl font-semibold text-gray-700">Статус</h4>
                <p class="text-gray-500">{{ lastDispatch.status }}</p>
              </div>
              <div class="flex flex-col">
                <h4 class="text-2xl font-semibold text-gray-700">Дата отправки</h4>
                <p class="text-gray-500">{{ lastDispatch.sendAt }}</p>
              </div>
              <div class="flex flex-col">
                <h4 class="text-2xl font-semibold text-gray-700">Обновлено</h4>
                <p class="text-gray-500">{{ lastDispatch.updatedAt }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col w-[40%] h-[620px] bg-white rounded-md shadow-sm px-5 py-6 text-left">
        <p class="text-2xl font-semibold text-gray-700 pb-5 border-b-[4px] border-[#4f46e5]">Последние действия</p>
        <div class="flex flex-col gap-6 mt-4">
          <!-- v-for -->
          <div class="flex w-full gap-4 items-center" :key="index" v-for="(item, index) in userHistory">
            <div class="p-3 bg-indigo-600 bg-opacity-75 rounded-full text-white">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
              </svg>
            </div>
            <div class="flex flex-col justify-start items-left">
              <p class="text-xl font-semibold text-gray-700">{{ item.title }}</p>
              <p class="text-gray-500">{{ item.desc }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.ag-root-wrapper{
  border-radius: 10px;
}
</style>