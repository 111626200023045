import { createApp } from 'vue'
import App from './App.vue'
import './index.css'
import VueRouter from 'vue-router'
import router from './router'
import Notifications from '@kyvg/vue3-notification'
import setupInterceptors from './services/setupInterceptors';


setupInterceptors();

let globalFunc = {
    methods: {
        addHistory(titlee, descc) {
            if (localStorage.getItem('userHistory')) {
                let arr = JSON.parse(localStorage.getItem('userHistory'));
                if (arr.length >= 7) {
                    arr.shift();
                    arr.push({title: titlee, desc: descc});
                    localStorage.setItem('userHistory', JSON.stringify(arr));
                } else {
                    arr.push({title: titlee, desc: descc});
                    localStorage.setItem('userHistory', JSON.stringify(arr));
                }
            } else {
                localStorage.setItem('userHistory', JSON.stringify([{title: titlee, desc: descc}]));
            }
        },
    }
}



createApp(App)
.use(router)
.use(VueRouter)
.use(Notifications)
.mixin(globalFunc)
.mount('#app')
